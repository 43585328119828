import { IHeadingFields } from 'contentful-types'

import { mapSpacer } from 'src/siteBuilder/renderer/contentful/mapSpacer'
import { withContentful } from 'src/siteBuilder/renderer/contentful/withContentful'

// Component
import Heading, { type HeadingProps } from './Heading'

import {
  CONTENTFUL_TEXT_COLOUR_MAPPER,
  CONTENTFUL_TEXT_SIZE_MAPPER,
  isVariantColor,
  typedLowerCase,
} from '@/siteBuilder/utils/text/mappers'

const ContentfulHeading = withContentful<IHeadingFields, HeadingProps>(Heading, {
  spacer: mapSpacer,
  colour: ({ fields: { colour, size } }) => {
    //If colour is one of the new variants, just return it
    if (isVariantColor(colour)) {
      return colour
    }
    // TODO: Add new colors to contentful and help people migrate to delete the following conditions
    if (size === 'Eyebrow' && colour === 'Secondary') return 'brand3'

    if (['Display Heading 1', 'Display Heading 2', 'H1', 'H2', 'H3'].includes(size) && colour === 'Secondary')
      return 'dark'

    if (size === 'H6' && colour === 'Tertiary') return 'light'

    return CONTENTFUL_TEXT_COLOUR_MAPPER[colour]
  },
  size: ({ fields: { size } }) => CONTENTFUL_TEXT_SIZE_MAPPER[size] as HeadingProps['size'],
  heading: ({ fields: { heading } }) => typedLowerCase(heading),
  align: ({ fields: { align } }) => typedLowerCase(align),
})

export default ContentfulHeading
